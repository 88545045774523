import React, { FC, useMemo } from 'react';
import { Grid, styled } from '@mui/material';
import { formatCurrency, formatPercent } from '../../../utils/formatters';
import { Text } from '../../../components/Text';
import { Colors } from '../../../theme/colors';
import { AddCircleOutline, PieChartOutline, WalletOutlined, WorkOutline } from '@mui/icons-material';
import { BeveledContainer } from '../../../components/BeveledContainer';
import { isDepositActive } from '../../../utils/deposits';
import { DepositDetailedInfo } from '../../../dto';
import { PER_MONTH_DEPOSIT_TYPE_NAME, END_TERM_DEPOSIT_TYPE_NAME } from '../../../store/constants';
import { BriefcaseIcon, ChartPieSliceIcon, PlusCircleIcon, WalletIcon } from '../../../components/Icons';

type Props = {
    deposit: DepositDetailedInfo;
};

const Root = styled('div')({
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    marginTop: '25px',
});

const Row = styled('div')({
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
});

type CardProps = {
    name: string;
    icon: React.ReactNode;
    value: string;
};

const ParamCard: FC<CardProps> = ({ name, icon, value }) => {
    return (
        <BeveledContainer style={{ height: '100%', boxSizing: 'border-box'}}>
            <Row>
                <Text variant="Small">{name}</Text>
                {icon}
            </Row>
            <Text variant="LargeTitle" color={Colors.darkBlue}>
                {value}
            </Text>
        </BeveledContainer>
    );
};

const PerMonthDepositParams: FC<Props> = ({ deposit }) => {
    // придет с бекенда
    const alreadyPaid = useMemo(() => {
        return deposit.operations.reduce((acc, operation) => {
            if (operation.typeName === 'Выплата дивидендов') {
                return acc + operation.sum
            }
            return acc
        }, 0)
    }, [deposit.operations])

    return (
        <Root>
            <Grid container spacing={'15px'}>
                <Grid item xs={6}>
                    <ParamCard
                        name={'Общий баланс с Инвесткопилки'}
                        icon={<WalletIcon style={{ flexShrink: 0}} />}
                        value={formatCurrency(deposit.sumTotal)}
                    />
                </Grid>
                <Grid item xs={6}>
                    <ParamCard
                        name={'Заработано за месяц'}
                        icon={<ChartPieSliceIcon style={{ flexShrink: 0}} />}
                        value={formatCurrency(deposit.lastMonthProfit)}
                    />
                </Grid>
                <Grid item xs={12} >
                    <ParamCard
                        name={'Уже выплачено'}
                        icon={<BriefcaseIcon style={{ flexShrink: 0}} />}
                        value={formatCurrency(alreadyPaid)}
                    />
                </Grid>
            </Grid>
        </Root>
    )
}

const EndTermDepositParams: FC<Props> = ({ deposit }) => { 
    return (
        <Root>
            <Grid container spacing={'15px'}>
                <Grid item xs={6}>
                    <ParamCard
                        name={'Общий баланс с Инвесткопилки'}
                        icon={<WalletIcon style={{ flexShrink: 0}} />}
                        value={formatCurrency(deposit.sumTotal)}
                    />
                </Grid>
                <Grid item xs={6}>
                    <ParamCard
                        name={'Цель вашей Инвесткопилки'}
                        icon={<ChartPieSliceIcon style={{ flexShrink: 0}} />}
                        value={'-'}
                    />
                </Grid>
                <Grid item xs={6} >
                    <ParamCard
                        name={'Вы внесли всего'}
                        icon={<BriefcaseIcon style={{ flexShrink: 0}} />}
                        value={formatCurrency(deposit.sum)}
                    />
                </Grid>
                <Grid item xs={6} >
                    <ParamCard
                        name={'Инвесткопилка уже добавила'}
                        icon={<PlusCircleIcon style={{ flexShrink: 0}} />}
                        value={`+ ${formatCurrency(deposit.sumTotal - deposit.sum)}`}
                    />
                </Grid>
            </Grid>
        </Root>
    )
}

export const DepositParams: FC<Props> = ({ deposit }) => {
    if (deposit.name === PER_MONTH_DEPOSIT_TYPE_NAME) {
        return (
            <PerMonthDepositParams deposit={deposit} />
        )
    }

    if (deposit.name === END_TERM_DEPOSIT_TYPE_NAME) {
        return (
            <EndTermDepositParams deposit={deposit} />
        )
    }
    return (
        <Root>
            <Grid container spacing={'15px'}>
                <Grid item xs={6}>
                    <ParamCard
                        name={'Ваши пополнения'}
                        icon={<WorkOutline />}
                        value={formatCurrency(deposit.sum || deposit.firstPayment)}
                    />
                </Grid>
                {isDepositActive(deposit) && (
                    <>
                        <Grid item xs={6}>
                            <ParamCard
                                name={'Ваш баланс'}
                                icon={<WalletOutlined />}
                                value={formatCurrency(deposit.sumTotal)}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <ParamCard
                                name={'Ваш доход за месяц'}
                                icon={<AddCircleOutline />}
                                value={`+ ${formatCurrency(deposit.lastMonthProfit)}`}
                            />
                        </Grid>
                    </>
                )}
                <Grid item xs={6}>
                    <ParamCard
                        name={'Доходность ваших инвестиций'}
                        icon={<PieChartOutline />}
                        value={formatPercent(deposit.profitPercent)}
                    />
                </Grid>
            </Grid>
        </Root>
    );
};
