import React from 'react'
import { Breadcrumbs } from '../../components/Breadcrumbs'
import { Layout } from "../../components/Layout"

export const HelpPage = () => {
    return (
        <Layout title={'помощь'}>
            <Breadcrumbs
                paths={[
                    { title: 'Главная', link: '/' },
                    { title: 'Помощь' },
                ]}
            />
            <div style={{ height: 'calc(100vh - 210px)'}}>
                {/* <iframe title="help" src="/index.html" style={{ border: 'none'}} height="100%" width="100%" ></iframe> */}
            </div> 
        </Layout>
    )
}